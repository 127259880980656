/*
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

"use strict";

var DataUtil = {
    add: function (data, name, value) {
        data.push(
            {
                name: name,
                value: value,
            }
        )
    }
};

module.exports = DataUtil;