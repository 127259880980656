// require('@coreui/coreui/dist/css/coreui.min.css');

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// require('bootstrap');

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../js/air-datepicker/css/datepicker.min.css';
import '../js/toastr/toastr.min.css';
import '../scss/app.scss';

// const $ = require('jquery');
// global.$ = $;
// global.jQuery = $;
// window.jQuery = $;
// const jQuery = $;
// import 'jquery/dist/jquery.min.js';

const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap';

// import '../js/money_util.js';
const DataUtil = require('../js/utils/data_util.js');
global.DataUtil = DataUtil;

const MoneyUtil = require('../js/utils/money_util.js');
global.MoneyUtil = MoneyUtil;

const PushStateUtil = require('../js/utils/push_state_util.js');
global.PushStateUtil = PushStateUtil;

// require('@fortawesome/fontawesome-free/css/all.min.css');
// require('@fortawesome/fontawesome-free/js/all.js');
const moment = require('moment');
global.moment = moment;

const accounting = require('../js/accounting.min.js');
global.accounting = accounting;

const toastr = require('../js/toastr/toastr.min.js');
global.toastr = toastr;

// import 'moment/moment.js';
import '@fortawesome/fontawesome-free/js/all.min.js';
// import '../js/accounting.min.js';
// import '../js/toastr/toastr.min.css';
// import '../js/toastr/toastr.min.js';
import '../js/air-datepicker/js/datepicker.min.js';
import '../js/air-datepicker/js/i18n/datepicker.en.js';
import '../js/air-datepicker/js/i18n/datepicker.de.js';

$(document).ready(function () {
    $('.toast').toast({
        delay: 5000,
        autohide: true
    }).toast('show');

    $('button.menu-toggler').on('click', function (e) {
        e.preventDefault();

        $('.sidebar-left').toggleClass('sidebar-show');
    });
});

// console.log(moment());