/*
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

"use strict";

var MoneyUtil = {
    toNet: function (price, tax) {
        return (price / (1 + (tax / 100)));
    }
};

module.exports = MoneyUtil;