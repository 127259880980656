/*
 * MWFewoManager
 *
 * @author Mirko Witzmann <info@mwfewomanager.de>
 * @copyright mwfewomanager.de
 *
 */

"use strict";

var PushStateUtil = {
    paramsToObject: function (params) {
        var params = (params == undefined) ? [] : params;
        var i = 0;
        var numData = params.length;

        var data = [];

        for (i = 0; i < numData; i++) {
            DataUtil.add(data, params[i].name, params[i].value);
        }

        return data;
    },

    paramsToQueryString: function (params) {
        var params = (params == undefined) ? [] : params;
        var queryString = [];
        var i = 0;
        var numData = params.length;

        for (i = 0; i < numData; i++) {
            var encodedValue = decodeURIComponent(params[i].value);

            queryString.push(params[i].name + '=' + encodedValue);
        }

        return queryString.join('&');
    },

    set: function (pathname, params) {
        var queryString = PushStateUtil.paramsToQueryString(params);

        window.history.pushState(
            PushStateUtil.paramsToObject(params),
            '',
            pathname + (queryString ? '?' + queryString : '')
        );
    }
};

module.exports = PushStateUtil;